:root {
    --main-red-color: #EF4629;
    --zety-color: #848168;
    --zety-circle: #434240;
    --gray-color: #F2F2F2;
    --text-black-color: #1A1A1A;
    --text-long-black: #1a1a1ad5;
}

body {
    //height: 2000px;
    width: 100vw;
    overflow-x: hidden;
    background-color: var(--gray-color);
}

p {
    line-height: 1.4;
}




.our-partner {
    .my-hero {
        position: relative;
        .my-img {
            img {
                
                position: absolute;
                z-index: -1;
                //width: 100%;
                width: 1420px;
                //width: 100vw;
                object-fit: contain;
            }
        }

        .content {
            margin-left: 4em;
            margin-right: 4em;
            padding-top: 9em;
            

            .head {
                margin-top: 21em;
                h1 {
                    font-size: 60px;
                    color: white;
                }

                hr {
                    width: 60%;
                    background-color: var(--main-red-color);
                    border: none;
                    height: 1px;
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    margin-top: 2em;

                    h5 {
                        color: var(--gray-color);
                    }

                    i {
                        color: var(--main-red-color);
                    }
                }
            }

            p {
                color: var(--gray-color);
                width: 60%;
                padding: 2em 0;
            }
        }
    }

    .main-content {
        margin-top: 16em;
        position: relative;
        //z-index: 2;
        //background-color: white;
        margin-left: 4em;
        margin-right: 4em;
        border-radius: 0.5em;
        //height: 100%;

        span.circle {
            width: 500px;
            height: 500px;
            background-color: var(--main-red-color);
            position: absolute;
            left: -100px;
            top: -300px;
            border-radius: 50%;
            opacity: 0.2;
            z-index: -2;
        }

        .clints {
            //background-color: var(--zety-color);
            
    
            //margin-top: 6em;
            position: relative;
            //z-index: 5;
    
            .tow-circle {
                position: relative;
                //right: 0;
    
                i {
                    position: absolute;
                    right: -50px;
                    top: -50px;
                    //bottom: 0;
                    font-size: 300px;
                    color: var(--zety-circle);
    
                    &:nth-child(2) {
                        right: -50px;
                        top: -50px;
                        color:#55534f;
                        font-size: 280px;
                    }
                }
        }
    
            span.circle {
                width: 400px;
                height: 400px;
                background-color: var(--zety-circle);
                position: absolute;
                left: -100px;
                top: -150px;
                border-radius: 50%;
                opacity: 0.2;
                z-index: 0;
            }
    
            .content {
                display: flex;
                align-items: center;
                justify-content: space-around;
                position: relative;
                z-index: 5;
    
                
    
                .left {
                    //order: 1;
                    width: 50%;
                    margin-left: 6em;
                    padding-top: 6em;
                    padding-bottom: 6em;
                    position: relative;
    
                    h5 {
                        color: var(--text-long-black);
                        font-size: 16px;
    
                        span {
                            width: 40px;
                            height: 3px;
                            background-color: var(--main-red-color);
                            position: absolute;
                            //left: -4em;
                            top: 7.5em;
                        }
                    }
                    
                }
    
                .right {
                    color: var(--text-long-black);
                }
            }
    
            .logos {
                position: relative;
                z-index: 5;
    
                display: flex;
                justify-content: center;
                gap: 1.5em;
    
                
                img {
                    //width: 20%;
                    width: 220px;
                    margin-bottom: 6em;
                }
            }
        }
    }
}



















/* 
    Start Media
*/
@media(max-width:992px) {
    .our-partner {
        overflow-x: hidden;
        overflow-y: hidden;
        .my-hero {
            .content {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}










@media(max-width:767px) {
    .our-partner {
        .my-hero {
            
            .content {
                text-align: center;
                margin-left: 0;
                //margin-right: auto;
                p {
                    font-size: 13px;
                    width: 100%;
                    padding: 2em 4em;
                }

                .head {
                    h1 {
                        font-size: 30px;
                    }

                    hr {
                        margin: 0 auto;
                    }

                    .title {
                        justify-content: center;
                    }
                }
            }

            
        }

        .main-content {
            .clints {
                .content {
                    //display: block;
                    text-align: center;
                    flex-direction: column-reverse;
                    padding-top: 5em;
    
                    .left {
                        margin-left: 0em;
                    }
                }
    
                .logos {
                    flex-wrap: wrap;
    
                    img {
                        margin-bottom: 2em;
                    }
                }
            }
        }
    }
}














@media(max-width:480px) {
    .our-partner {
        //overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            

            .content {
                margin-left: 0em;
                margin-right: 0em;

                .head {
                    margin-top: 15em;
                    margin-bottom: 3em;
                }

                p {
                    font-size: 16px;
                    padding: 2em 1.5em;
                }
            }
        }

        .main-content {
            margin-top: 20em;
            .clints {
                width: 100%;
                //overflow-x: hidden;
                overflow: hidden;

                .content {
                    .left {
                        width: 100%;
                    }
                }
            }

            
        }
    }
}