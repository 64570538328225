:root {
    --main-red-color: #EF4629;
    --zety-color: #848168;
    --zety-circle: #434240;
    --gray-color: #F2F2F2;
    --text-black-color: #1A1A1A;
    --text-long-black: #1a1a1ad5;
}

body {
    //height: 4000px;
    width: 100vw;
    overflow-x: hidden;
    
}

p {
    line-height: 1.4;
}

.my-home {
    
    .hero {
        background-color: var(--zety-color);
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 650px;
        position: relative;
        z-index: 3;

        .left {
            
            h1 {
                font-size: 4em;
                color: var(--gray-color);
                padding-left: 1em;
                padding-right: 4em;
            }

            .btn {
                padding-left: 4em;
                padding-right: 4em;
                margin-top: 4em;
                
                button {
                    padding: 0.5em;
                    width: 12em;
                    cursor: pointer;
                    border-radius: 0.5em;
                    outline: none;
                    border: 1px solid transparent;
                    margin-left: 0.5em;
                    margin-top: 0.5em;
                    font-size: 1.3em;
                    font-weight: bold;
                    transition: 0.3s ease;
                    
                    &:nth-child(1) {
                        background-color: var(--main-red-color);
                        color: var(--gray-color);

                        i {
                            margin-left: 0.5em;
                        }

                        &:hover {
                            background-color: transparent;
                            border: 1px solid var(--gray-color);
                            transition: 0.3s ease;
                        }
                    }

                    
                }

                button.secound {
                    background-color: transparent;
                    border: 1px solid var(--gray-color);
                    color: var(--gray-color);

                    &:hover {
                        background-color: var(--main-red-color);
                        border-color: transparent;
                        transition: 0.3s ease;
                    }
                }
            }
        }

        .right {
            
            img {
                width: 25%;

                &:nth-child(2) {
                    transform: translateY(5em);
                }

                &:nth-child(3) {
                    transform: translateY(3em);
                }

                &:nth-child(4) {
                    transform: translateY(1em);
                }
            }

            
        }
    }

    .social {
        background-color: var(--gray-color);
        display: flex;
        align-items: center;
        justify-content: space-around;

        //margin-top: 6em;
        position: relative;
        z-index: 2;

        span.circle {
            width: 500px;
            height: 500px;
            background-color: var(--main-red-color);
            position: absolute;
            left: -100px;
            top: -150px;
            border-radius: 50%;
            opacity: 0.2;
            z-index: -1;
        }

        .left {
            order: 1;
            width: 50%;
            margin-left: 6em;
            margin-right: 6em;
            padding-top: 6em;
            padding-bottom: 6em;
            position: relative;

            h5 {
                font-size: 16px;
                span {
                    width: 40px;
                    height: 3px;
                    background-color: var(--main-red-color);
                    position: absolute;
                    //left: -4em;
                    //top: 7.8em;
                    top: 7.5em;
                }
            }
           

            h3 {
                width: 80%;
                //margin-top: 12em;
                padding-top: 6em;
                padding-bottom: 3em;
                color: var(--main-red-color);
            }

            p {
                color: var(--text-long-black);
                width: 80%;
            }

            button.my-but {
                background-color: var(--main-red-color);
                color: var(--gray-color);
                padding: 0.5em;
                width: 12em;
                cursor: pointer;
                border-radius: 0.5em;
                outline: none;
                border: 1px solid transparent;
                margin-left: 0.5em;
                margin-top: 3em;
                font-size: 1em;
                font-weight: bold;
                transition: 0.3s ease;

                i {
                    margin-left: 0.5em;
                    transform: rotate(-45deg);
                    transition: 0.3s ease;
                }

                &:hover i {
                        transform: rotate(0deg);
                        transition: 0.3s ease;
                    }

                &:hover {
                    color: var(--main-red-color);
                    background-color: transparent;
                    border: 1px solid var(--main-red-color);
                    transition: 0.3s ease;
                } 
            }
        }

        .right {
            order: 2;
            width: 50%;
            padding-top: 6em;
            padding-bottom: 6em;
            //width: 300px;
            
            h1 {
                color: var(--text-black-color);
                padding-bottom: 2em;
            }

            img {
                width: 100%;
                //margin-bottom: 6em;
            }
        }
    }

    .clints {
        background-color: var(--zety-color);
        

        //margin-top: 6em;
        position: relative;
        //z-index: 5;

        .tow-circle {
            position: relative;
            //right: 0;

            i {
                position: absolute;
                right: -50px;
                top: -50px;
                //bottom: 0;
                font-size: 300px;
                color: var(--zety-circle);

                &:nth-child(2) {
                    right: -50px;
                    top: -50px;
                    color:#55534f;
                    font-size: 280px;
                }
            }
    }

        span.circle {
            width: 400px;
            height: 400px;
            background-color: var(--zety-circle);
            position: absolute;
            left: -100px;
            top: -150px;
            border-radius: 50%;
            opacity: 0.2;
            z-index: 0;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: relative;
            z-index: 5;

            

            .left {
                //order: 1;
                width: 50%;
                margin-left: 6em;
                padding-top: 6em;
                padding-bottom: 6em;
                position: relative;

                h5 {
                    color: var(--gray-color);
                    font-size: 16px;

                    span {
                        width: 40px;
                        height: 3px;
                        background-color: var(--main-red-color);
                        position: absolute;
                        //left: -4em;
                        top: 7.5em;
                    }
                }
                
            }

            .right {
                color: var(--gray-color);
            }
        }

        .logos {
            position: relative;
            z-index: 5;

            display: flex;
            justify-content: center;
            gap: 1.5em;

            
            img {
                //width: 20%;
                width: 220px;
                margin-bottom: 6em;
            }
        }
    }
}








/* 
    Start Media
*/
@media(max-width:992px) {
    .my-home {
        .hero {
            .left {
                h1 {
                    padding-right: 1em;
                }
            }
        }
    }
}









@media(max-width:767px) {
    .my-home {
        .hero {
            .left {
                h1 {
                    padding-right: 0.5em;
                    font-size: 2.5em;
                }

                .btn {
                    padding-left: 2em;

                    button {
                        width: 11em;
                        font-size: 1em;
                    }
                }
            }
        }

        .social {
            flex-direction: column-reverse;

            justify-content: center;
            text-align: center;

            .left {
                order: 1;
                width: 80%;

                margin-left: 0em;
                text-align: center;
                margin: 0 auto;

                span {
                    left: 20%;
                }

                h3 {
                    width: 100%;
                }

                p {
                    width: 100%;
                }
            }

            .right {
                order: 2;
                width: 80%;
            }
        }

        .clints {
            .content {
                //display: block;
                text-align: center;
                flex-direction: column-reverse;
                padding-top: 5em;

                .left {
                    margin-left: 0em;
                }
            }

            .logos {
                flex-wrap: wrap;

                img {
                    margin-bottom: 2em;
                }
            }
        }
    }
}
















@media(max-width:480px) {
    .my-home {
        width: 100vw;
        overflow-x: hidden;
        .hero {
            height: 300px;
            .left {
                h1 {
                    padding-right: 0.8em;
                    //padding-left: 0em;
                    padding-top: 3em;
                    font-size: 1.5em;
                }

                .btn {
                    margin-top: 1em;
                    padding-left: 1em;
                    padding-right: 0em;

                    button {
                        width: 11em;
                        font-size: 0.7em;
                    }
                }
            }

            .right {
                img {
                    //width: 25%;
    
                    &:nth-child(2) {
                        transform: translateY(2em);
                    }
    
                    &:nth-child(3) {
                        transform: translateY(1em);
                    }
    
                    &:nth-child(4) {
                        transform: translateY(0em);
                    }
                }
            }
        }

        .social {
            .left {
                padding-top: 1em;
                margin-left: 0em;
                margin-right: 0em;

                h5 {
                    span {
                        left: 3%;
                        top: 1.5em;
                    }
                }
            }

            .right {
                h1 {
                    text-align: center;
                    font-size: 20px;
                }
            }
        }

        .clints {
            width: 100%;
            //overflow-x: hidden;
            overflow: hidden;

            .content {
                .left {
                    margin-left: 0em;
                    width: 100%;

                    h5 {
                        span {
                            top: 8em;
                        }
                    }
                }
            }
        }
    }
}

/* 
    End Media
*/