:root {
    --main-red-color: #EF4629;
    --zety-color: #848168;
    --zety-circle: #434240;
    --gray-color: #F2F2F2;
    --text-black-color: #1A1A1A;
    --text-long-black: #1a1a1ad5;
}

body {
    //height: 2000px;
    width: 100vw;
    overflow-x: hidden;
    background-color: var(--gray-color);
}

p {
    line-height: 1.4;
}




.master-our-project {
    .my-hero {
        position: relative;
        background-color: var(--zety-color);
        height: 100vh;

        .my-para {
            width: 80%;
            background-color: var(--zety-color);
            margin: 0 auto;

            p {
                text-align: center;
                padding: 6.5em 3em 2em;
                color: var(--gray-color);
                font-weight: bold;
                font-size: 18px;
                background-color: #ef4444;
                border-radius: 1em;
            }
        }

        
        .my-img {
            display: flex;
            justify-content: center;
            //margin-top: 5em;
            padding-top: 2em;
            background-color: var(--zety-color);
            z-index: 1;
            position: relative;
            width: 80%;
            margin: 0 auto;

            .img-one {
                position: relative;
                //width: 70%;

                img {
                    width: 100%;
                }

                .content {
                    position: absolute;
                    top: 50%;
                    left: 30%;
                    transform: translateX(-50%);
                    text-align: center;

                    h1 {
                        color: var(--gray-color);
                    }

                    i {
                        margin-top: 0.5em;
                        color: var(--main-red-color);
                        font-size: 45px;
                    }
                }

            }
            img {
                width: 25%;
                cursor: pointer;
                object-fit: contain;
                
                &:nth-child(1) {
                    filter: grayscale(1);
                    transition: 1s ease;

                    &:hover {
                        filter: grayscale(0);
                        transition: 1s ease;
                    }
                }

                &:nth-child(2) {
                    filter: grayscale(1);
                    transition: 1s ease;

                    &:hover {
                        filter: grayscale(0);
                        transition: 1s ease;
                    }
                }

                &:nth-child(3) {
                    filter: grayscale(1);
                    transition: 1s ease;

                    &:hover {
                        filter: grayscale(0);
                        transition: 1s ease;
                    }
                }
            }
        }

        .content {
            margin-left: 4em;
            padding-top: 2em;
            z-index: 2;
            position: relative;

            p {
                //color: var(--gray-color);
                width: 60%;
                padding: 2em 0;
            }
        }
    }

    .main-content {
        margin-top: 7em;
        position: relative;
        //z-index: 2;
        //background-color: white;
        margin-left: 4em;
        margin-right: 4em;
        border-radius: 0.5em;
        //height: 100%;

        span.circle {
            width: 500px;
            height: 500px;
            background-color: var(--main-red-color);
            position: absolute;
            left: -100px;
            top: -300px;
            border-radius: 50%;
            opacity: 0.2;
            z-index: -2;
        }

        .small-title {
            order: 1;
            width: 50%;
            margin-left: 6em;
            padding-top: 2.5em;
            padding-bottom: 6em;
            position: relative;

            h5 {
                font-size: 20px;

                span {
                    width: 40px;
                    height: 3px;
                    background-color: var(--main-red-color);
                    position: absolute;
                    //left: -4em;
                    top: 3em;
                }
            }

            
        }

        .big-title {
            h1 {
                color: var(--text-black-color);
                text-align: center;
                font-size: 45px;
            }
        }

        .all-EQUIPMENT {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 6em;

            .card {
                position: relative;
                width: 30%;
                margin-bottom: 6em;

                h2 {
                    color: var(--main-red-color);
                    position: absolute;
                    left: 43%;
                    top: 5%;
                    transform: translateX(-35%);
                }

                h2.WHEEL-LOADER {
                    font-size: 23px;
                }

                h2.FORKLIFT-TRUCK {
                    font-size: 22px;
                }

                h2.MOBILE-ELEVATING-ELECTRICITY,
                h2.FLAT-BED-TRAILER,
                h2.TRUCKS-MERCEDES {
                    font-size: 20px;
                }

                img {
                    width: 100%;
                }
            }
        }

    }
}



















/* 
    Start Media
*/
@media(max-width:992px) {
    .master-our-project {
        overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            .my-img {
                .img-one {
                    .content {
                        left: 20%;

                        h1 {
                            font-size: 20px;
                        }

                        i {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .main-content {
            .all-EQUIPMENT {
                .card {
                    h2 {
                        font-size: 18px;
                    }

                    h2.WHEEL-LOADER {
                        font-size: 16px;
                    }

                    h2.FORKLIFT-TRUCK {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}













@media(max-width:767px) {
    .master-our-project {
        overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            height: auto;

            .my-img {

                display: block;

                .img-one {
                    //margin: 0 auto;
                    text-align: center;

                    img {
                        width: 80%;
                        //margin: 0 auto;
                    }
                    .content {
                        left: 40%;

                        h1 {
                            font-size: 35px;
                        }

                        i {
                            font-size: 40px;
                        }
                    }
                }
            }
        }

        .main-content {
            .all-EQUIPMENT {
                //display: block;
                .card {
                    width: 50%;
                    h2 {
                        font-size: 18px;
                    }

                    h2.WHEEL-LOADER {
                        font-size: 16px;
                    }

                    h2.FORKLIFT-TRUCK {
                        font-size: 15px;
                    }

                    
                }
            }
        }
    }
}












@media(max-width:480px) {
    .master-our-project {
        overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            height: auto;

            .my-para {
                p {
                    padding: 6.5em 2em 2em;
                }
            }

            .my-img {

                display: block;

                .img-one {
                    //margin: 0 auto;
                    text-align: center;

                    img {
                        width: 100%;
                        //margin: 0 auto;
                    }
                    .content {
                        left: 30%;

                        h1 {
                            font-size: 25px;
                        }

                        i {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        .main-content {
            .all-EQUIPMENT {
                display: block;
                .card {
                    width: 130%;
                    transform: translateX(-12%);
                    h2 {
                        font-size: 18px;
                    }

                    h2.WHEEL-LOADER {
                        font-size: 16px;
                    }

                    h2.FORKLIFT-TRUCK {
                        font-size: 15px;
                    }

                    
                }
            }
        }
    }
}






