:root {
    --main-red-color: #EF4629;
    --zety-color: #848168;
    --zety-circle: #434240;
    --gray-color: #F2F2F2;
    --text-black-color: #1A1A1A;
    --text-long-black: #1a1a1ad5;
}

body {
    //height: 2000px;
    width: 100vw;
    overflow-x: hidden;
    background-color: var(--gray-color);
}

p {
    line-height: 1.4;
}




.who-we-are {
    .my-hero {
        position: relative;
        .my-img {
            img {
                
                position: absolute;
                z-index: -1;
                //width: 100%;
                width: 1420px;
                //width: 100vw;
                object-fit: contain;
            }
        }

        .content {
            margin-left: 4em;
            margin-right: 4em;
            padding-top: 5em;
            p {
                color: var(--gray-color);
                width: 60%;
                padding: 1.5em 0;
            }

            .head {
                h1 {
                    font-size: 60px;
                    color: white;
                }

                hr {
                    width: 60%;
                    background-color: var(--main-red-color);
                    border: none;
                    height: 1px;
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    margin-top: 2em;

                    h5 {
                        color: var(--gray-color);
                    }

                    i {
                        color: var(--main-red-color);
                    }
                }
            }
        }
    }

    .main-content {
        margin-top: 16em;
        position: relative;
        //z-index: 2;
        background-color: white;
        margin-left: 4em;
        margin-right: 4em;
        border-radius: 0.5em;
        //height: 100%;

        span.circle {
            width: 500px;
            height: 500px;
            background-color: var(--main-red-color);
            position: absolute;
            left: -100px;
            top: -300px;
            border-radius: 50%;
            opacity: 0.2;
            z-index: -2;
        }

        .blok {
            margin-bottom: 0em;
            h1 {
                color: var(--main-red-color);
                padding-top: 2em;
                padding-left: 2em;
                padding-right: 2em;
                padding-bottom: 1em;
            }



            .MSG {
                display: flex;
                align-items: center;
                //justify-content: space-around;
                gap: 6em;
                padding-left: 4em;
                padding-right: 4em;

                


                i {
                    color: var(--main-red-color);
                    font-size: 40px;
                }

                p {
                    color: var(--text-long-black);
                    font-size: 15px;
                    width: 60%;

                    // &:nth-child(2) {
                    //     width: 30%;
                    // }

                    span {
                        color: var(--main-red-color);
                        font-weight: bold;
                    }
                }

                img {
                    width: 40%;
                }

                .obj {
                    h4 {
                        color: var(--main-red-color);
                    }
                    p {
                        width: 100%;
                    }
                }
            }

            // .last {
            //     padding-bottom: 0em;
            // }
        }
    }
}



















/* 
    Start Media
*/
@media(min-width:992px) {
    .who-we-are {
        .main-content {
            .blok {
                &:nth-child(3) {
                    .MSG {
                        i {
                            transform: translateY(-5em);
                        }
                        p {
                            transform: translateY(-13em);
                        }
                    }
                }

                &:nth-child(4) {
                    
                        transform: translateY(-22em);
                    
                }
            }
        }
    }
}



@media(max-width:992px) {
    .who-we-are {
        overflow-x: hidden;
        overflow-y: hidden;
        .my-hero {
            .content {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}










@media(max-width:767px) {
    .who-we-are {
        .my-hero {
            // .my-img {
            //     img {
            //         width: 1200px;
            //     }
            // }

            .content {
                text-align: center;
                margin-left: 0;
                //margin-right: auto;
                p {
                    font-size: 13px;
                    width: 100%;
                    padding: 2em 4em;
                }

                .head {
                    h1 {
                        font-size: 30px;
                    }

                    hr {
                        margin: 0 auto;
                    }

                    .title {
                        justify-content: center;
                    }
                }
            }

            
        }

        .main-content {
            .blok {
                .MSG {
                    img {
                        display: none;
                    }
                }
            }
        }
    }
}














@media(max-width:480px) {
    .who-we-are {
        //overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            

            .content {
                margin-left: 0em;
                margin-right: 0em;

                p {
                    font-size: 12px;
                }
            }
        }

        .main-content {
            margin-top: 8em;
            margin-left: 2em;
            margin-right: 2em;

            .blok {
                text-align: center;
                h1 {
                    padding-left: 0em;
                    padding-right: 0em;
                }

                .MSG {
                    display: block;
                    padding-left: 0em;
                    padding-right: 0em;

                    p {
                        margin: 1em auto;
                        width: 80%;
                    }

                    img {
                        display: block;
                        margin: 2em auto;
                        width: 80%;
                    }

                    .obj {
                        h4 {
                            width: 80%;
                            margin: 2em auto;
                        }
                        p {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}