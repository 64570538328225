:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --zety-circle: #434240;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
  --text-long-black: #1a1a1ad5;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

p {
  line-height: 1.4;
}

.my-home .hero {
  background-color: var(--zety-color);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 650px;
  position: relative;
  z-index: 3;
}
.my-home .hero .left h1 {
  font-size: 4em;
  color: var(--gray-color);
  padding-left: 1em;
  padding-right: 4em;
}
.my-home .hero .left .btn {
  padding-left: 4em;
  padding-right: 4em;
  margin-top: 4em;
}
.my-home .hero .left .btn button {
  padding: 0.5em;
  width: 12em;
  cursor: pointer;
  border-radius: 0.5em;
  outline: none;
  border: 1px solid transparent;
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-size: 1.3em;
  font-weight: bold;
  transition: 0.3s ease;
}
.my-home .hero .left .btn button:nth-child(1) {
  background-color: var(--main-red-color);
  color: var(--gray-color);
}
.my-home .hero .left .btn button:nth-child(1) i {
  margin-left: 0.5em;
}
.my-home .hero .left .btn button:nth-child(1):hover {
  background-color: transparent;
  border: 1px solid var(--gray-color);
  transition: 0.3s ease;
}
.my-home .hero .left .btn button.secound {
  background-color: transparent;
  border: 1px solid var(--gray-color);
  color: var(--gray-color);
}
.my-home .hero .left .btn button.secound:hover {
  background-color: var(--main-red-color);
  border-color: transparent;
  transition: 0.3s ease;
}
.my-home .hero .right img {
  width: 25%;
}
.my-home .hero .right img:nth-child(2) {
  transform: translateY(5em);
}
.my-home .hero .right img:nth-child(3) {
  transform: translateY(3em);
}
.my-home .hero .right img:nth-child(4) {
  transform: translateY(1em);
}
.my-home .social {
  background-color: var(--gray-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 2;
}
.my-home .social span.circle {
  width: 500px;
  height: 500px;
  background-color: var(--main-red-color);
  position: absolute;
  left: -100px;
  top: -150px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -1;
}
.my-home .social .left {
  order: 1;
  width: 50%;
  margin-left: 6em;
  margin-right: 6em;
  padding-top: 6em;
  padding-bottom: 6em;
  position: relative;
}
.my-home .social .left h5 {
  font-size: 16px;
}
.my-home .social .left h5 span {
  width: 40px;
  height: 3px;
  background-color: var(--main-red-color);
  position: absolute;
  top: 7.5em;
}
.my-home .social .left h3 {
  width: 80%;
  padding-top: 6em;
  padding-bottom: 3em;
  color: var(--main-red-color);
}
.my-home .social .left p {
  color: var(--text-long-black);
  width: 80%;
}
.my-home .social .left button.my-but {
  background-color: var(--main-red-color);
  color: var(--gray-color);
  padding: 0.5em;
  width: 12em;
  cursor: pointer;
  border-radius: 0.5em;
  outline: none;
  border: 1px solid transparent;
  margin-left: 0.5em;
  margin-top: 3em;
  font-size: 1em;
  font-weight: bold;
  transition: 0.3s ease;
}
.my-home .social .left button.my-but i {
  margin-left: 0.5em;
  transform: rotate(-45deg);
  transition: 0.3s ease;
}
.my-home .social .left button.my-but:hover i {
  transform: rotate(0deg);
  transition: 0.3s ease;
}
.my-home .social .left button.my-but:hover {
  color: var(--main-red-color);
  background-color: transparent;
  border: 1px solid var(--main-red-color);
  transition: 0.3s ease;
}
.my-home .social .right {
  order: 2;
  width: 50%;
  padding-top: 6em;
  padding-bottom: 6em;
}
.my-home .social .right h1 {
  color: var(--text-black-color);
  padding-bottom: 2em;
}
.my-home .social .right img {
  width: 100%;
}
.my-home .clints {
  background-color: var(--zety-color);
  position: relative;
}
.my-home .clints .tow-circle {
  position: relative;
}
.my-home .clints .tow-circle i {
  position: absolute;
  right: -50px;
  top: -50px;
  font-size: 300px;
  color: var(--zety-circle);
}
.my-home .clints .tow-circle i:nth-child(2) {
  right: -50px;
  top: -50px;
  color: #55534f;
  font-size: 280px;
}
.my-home .clints span.circle {
  width: 400px;
  height: 400px;
  background-color: var(--zety-circle);
  position: absolute;
  left: -100px;
  top: -150px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: 0;
}
.my-home .clints .content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 5;
}
.my-home .clints .content .left {
  width: 50%;
  margin-left: 6em;
  padding-top: 6em;
  padding-bottom: 6em;
  position: relative;
}
.my-home .clints .content .left h5 {
  color: var(--gray-color);
  font-size: 16px;
}
.my-home .clints .content .left h5 span {
  width: 40px;
  height: 3px;
  background-color: var(--main-red-color);
  position: absolute;
  top: 7.5em;
}
.my-home .clints .content .right {
  color: var(--gray-color);
}
.my-home .clints .logos {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  gap: 1.5em;
}
.my-home .clints .logos img {
  width: 220px;
  margin-bottom: 6em;
}

/* 
    Start Media
*/
@media (max-width: 992px) {
  .my-home .hero .left h1 {
    padding-right: 1em;
  }
}
@media (max-width: 767px) {
  .my-home .hero .left h1 {
    padding-right: 0.5em;
    font-size: 2.5em;
  }
  .my-home .hero .left .btn {
    padding-left: 2em;
  }
  .my-home .hero .left .btn button {
    width: 11em;
    font-size: 1em;
  }
  .my-home .social {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
  }
  .my-home .social .left {
    order: 1;
    width: 80%;
    margin-left: 0em;
    text-align: center;
    margin: 0 auto;
  }
  .my-home .social .left span {
    left: 20%;
  }
  .my-home .social .left h3 {
    width: 100%;
  }
  .my-home .social .left p {
    width: 100%;
  }
  .my-home .social .right {
    order: 2;
    width: 80%;
  }
  .my-home .clints .content {
    text-align: center;
    flex-direction: column-reverse;
    padding-top: 5em;
  }
  .my-home .clints .content .left {
    margin-left: 0em;
  }
  .my-home .clints .logos {
    flex-wrap: wrap;
  }
  .my-home .clints .logos img {
    margin-bottom: 2em;
  }
}
@media (max-width: 480px) {
  .my-home {
    width: 100vw;
    overflow-x: hidden;
  }
  .my-home .hero {
    height: 300px;
  }
  .my-home .hero .left h1 {
    padding-right: 0.8em;
    padding-top: 3em;
    font-size: 1.5em;
  }
  .my-home .hero .left .btn {
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 0em;
  }
  .my-home .hero .left .btn button {
    width: 11em;
    font-size: 0.7em;
  }
  .my-home .hero .right img:nth-child(2) {
    transform: translateY(2em);
  }
  .my-home .hero .right img:nth-child(3) {
    transform: translateY(1em);
  }
  .my-home .hero .right img:nth-child(4) {
    transform: translateY(0em);
  }
  .my-home .social .left {
    padding-top: 1em;
    margin-left: 0em;
    margin-right: 0em;
  }
  .my-home .social .left h5 span {
    left: 3%;
    top: 1.5em;
  }
  .my-home .social .right h1 {
    text-align: center;
    font-size: 20px;
  }
  .my-home .clints {
    width: 100%;
    overflow: hidden;
  }
  .my-home .clints .content .left {
    margin-left: 0em;
    width: 100%;
  }
  .my-home .clints .content .left h5 span {
    top: 8em;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_Home.css.map */