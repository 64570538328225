* {
  scroll-behavior: smooth;
}

:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --zety-circle: #434240;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
  --text-long-black: #1a1a1ad5;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--gray-color);
}

p {
  line-height: 1.4;
}

.our-services .my-hero {
  position: relative;
  background-color: var(--zety-color);
  height: 100vh;
}
.our-services .my-hero .my-para {
  width: 80%;
  background-color: var(--zety-color);
  margin: 0 auto;
}
.our-services .my-hero .my-para p {
  text-align: center;
  padding: 6.5em 3em 2em;
  color: var(--gray-color);
  font-weight: bold;
  font-size: 18px;
  background-color: #ef4444;
  border-radius: 1em;
}
.our-services .my-hero .my-img {
  display: flex;
  justify-content: center;
  padding-top: 1.5em;
  background-color: var(--zety-color);
  z-index: 1;
  position: relative;
  width: 80%;
  margin: 0 auto;
}
.our-services .my-hero .my-img .img-one {
  position: relative;
}
.our-services .my-hero .my-img .img-one img {
  width: 100%;
}
.our-services .my-hero .my-img .img-one .content {
  position: absolute;
  top: 60%;
  left: 20%;
  transform: translateX(-25%);
  text-align: center;
}
.our-services .my-hero .my-img .img-one .content h1 {
  color: var(--gray-color);
}
.our-services .my-hero .my-img .img-one .content i {
  margin-top: 0.5em;
  color: var(--main-red-color);
  font-size: 45px;
}
.our-services .my-hero .my-img img {
  width: 25%;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
}
.our-services .my-hero .my-img img:nth-child(1) {
  filter: grayscale(1);
  transition: 1s ease;
}
.our-services .my-hero .my-img img:nth-child(1):hover {
  filter: grayscale(0);
  transition: 1s ease;
}
.our-services .my-hero .my-img img:nth-child(2) {
  filter: grayscale(1);
  transition: 1s ease;
}
.our-services .my-hero .my-img img:nth-child(2):hover {
  filter: grayscale(0);
  transition: 1s ease;
}
.our-services .my-hero .content {
  margin-left: 4em;
  padding-top: 2em;
  z-index: 2;
  position: relative;
}
.our-services .my-hero .content p {
  width: 60%;
  padding: 2em 0;
}
.our-services .main-content {
  margin-top: 16em;
  position: relative;
  background-color: white;
  margin-left: 4em;
  margin-right: 4em;
  border-radius: 0.5em;
  padding-bottom: 0.5em;
}
.our-services .main-content span.circle {
  width: 500px;
  height: 500px;
  background-color: var(--main-red-color);
  position: absolute;
  left: -100px;
  top: -400px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -2;
}
.our-services .main-content .blok {
  margin-bottom: 8em;
}
.our-services .main-content .blok .head {
  margin-top: 7em;
}
.our-services .main-content .blok .head h1 {
  font-size: 60px;
  color: var(--main-red-color);
  padding-top: 2em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}
.our-services .main-content .blok .head hr {
  width: 60%;
  background-color: var(--main-red-color);
  border: none;
  height: 1px;
  margin-left: 4em;
  margin-right: 4em;
}
.our-services .main-content .blok .head .title {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
}
.our-services .main-content .blok .head .title h5 {
  color: var(--text-long-black);
}
.our-services .main-content .blok .head .title i {
  color: var(--main-red-color);
}
.our-services .main-content .blok .all-content {
  display: flex;
}
.our-services .main-content .blok .all-content .my-left .my-content {
  padding-top: 2.5em;
  padding-left: 4em;
  padding-right: 4em;
}
.our-services .main-content .blok .all-content .my-left .my-content:nth-child(4) p, .our-services .main-content .blok .all-content .my-left .my-content:nth-child(5) p, .our-services .main-content .blok .all-content .my-left .my-content:nth-child(6) p {
  width: 80%;
}
.our-services .main-content .blok .all-content .my-left .my-content p {
  width: 80%;
  color: var(--text-long-black);
}
.our-services .main-content .blok .all-content .my-left .my-content p span {
  color: var(--main-red-color);
  font-size: 30px;
  font-weight: bold;
}
.our-services .main-content .blok .all-content .my-right {
  margin-right: 4em;
  margin-left: 6em;
}
.our-services .main-content .blok .all-content .my-right img {
  width: 120%;
}

/* 
    Start Media
*/
@media (max-width: 992px) {
  .our-services {
    overflow-x: hidden;
  }
  .our-services .my-hero .my-img .img-one .content {
    left: 20%;
  }
  .our-services .my-hero .my-img .img-one .content h1 {
    font-size: 20px;
  }
  .our-services .my-hero .my-img .img-one .content i {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .our-services {
    overflow-x: hidden;
  }
  .our-services .my-hero {
    height: auto;
  }
  .our-services .my-hero .my-img {
    display: block;
  }
  .our-services .my-hero .my-img .img-one {
    text-align: center;
  }
  .our-services .my-hero .my-img .img-one img {
    width: 80%;
  }
  .our-services .my-hero .my-img .img-one .content {
    top: 50%;
    left: 22%;
  }
  .our-services .my-hero .my-img .img-one .content h1 {
    font-size: 35px;
  }
  .our-services .my-hero .my-img .img-one .content i {
    font-size: 40px;
  }
  .our-services .main-content .blok .all-content {
    display: block;
  }
  .our-services .main-content .blok .all-content .my-left .my-content:nth-child(4) p, .our-services .main-content .blok .all-content .my-left .my-content:nth-child(5) p, .our-services .main-content .blok .all-content .my-left .my-content:nth-child(6) p {
    width: 80%;
  }
  .our-services .main-content .blok .all-content .my-right {
    text-align: center;
  }
  .our-services .main-content .blok .all-content .my-right img {
    width: 60%;
    transform: translateX(0%);
    margin-top: 3em;
  }
}
@media (max-width: 480px) {
  .our-services {
    overflow-x: hidden;
  }
  .our-services .my-hero {
    height: auto;
  }
  .our-services .my-hero .my-para p {
    padding: 6.5em 2em 2em;
  }
  .our-services .my-hero .my-img {
    display: block;
  }
  .our-services .my-hero .my-img .img-one {
    text-align: center;
  }
  .our-services .my-hero .my-img .img-one img {
    width: 100%;
  }
  .our-services .my-hero .my-img .img-one .content {
    top: 35%;
    left: 15%;
  }
  .our-services .my-hero .my-img .img-one .content h1 {
    font-size: 25px;
  }
  .our-services .my-hero .my-img .img-one .content i {
    font-size: 30px;
  }
  .our-services .main-content {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 10em;
  }
  .our-services .main-content .blok .head h1 {
    font-size: 25px;
    text-align: center;
  }
  .our-services .main-content .blok .head .title h5 {
    font-size: 10px;
  }
  .our-services .main-content .blok .all-content .my-left .my-content {
    padding-right: 0em;
  }
  .our-services .main-content .blok .all-content .my-right {
    margin-right: 0em;
    margin-left: 0em;
  }
}/*# sourceMappingURL=style_OurServices.css.map */