:root {
    --footer-color: #1A1A1A;
    --gray-color: #F2F2F2;
}

.my-footer {
    background-color: var(--footer-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    //gap: 10em;

    img {
        width: 80px;
        margin-left: 4em;
        margin-right: 4em;
    }

    .my-links {
        display: flex;
        gap: 1.5em;
        margin-right: 4em;
        margin-left: 4em;

        a {
            cursor: pointer;

            &:hover {
                color: var(--gray-color);
            }
        }
    }
}


@media(max-width:480px) {
    .my-footer {
        img {
            margin-left: 2em;
        }
        .my-links {
            margin-right: 3em;
            margin-left: 1em;
        }
    }
}