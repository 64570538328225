* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Montserrat, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  /* font-family: Montserrat, 'Sukar'; */

  font-family: "Muna Regular";
  /* direction: ltr; */
}

@import url('https://fonts.cdnfonts.com/css/sukar');
@import url(https://db.onlinewebfonts.com/c/c1539bd6dcdc5e30b698772685321a5b?family=Muna+Regular);
@import url(https://db.onlinewebfonts.com/c/85040c569cc6193905af9f9ee765baf4?family=GE+Flow);

@font-face {
  font-family: "Muna Regular";
  src: url("https://db.onlinewebfonts.com/t/c1539bd6dcdc5e30b698772685321a5b.eot");
  src: url("https://db.onlinewebfonts.com/t/c1539bd6dcdc5e30b698772685321a5b.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/c1539bd6dcdc5e30b698772685321a5b.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/c1539bd6dcdc5e30b698772685321a5b.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/c1539bd6dcdc5e30b698772685321a5b.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/c1539bd6dcdc5e30b698772685321a5b.svg#Muna Regular")format("svg");
}


@font-face {
    font-family: "GE Flow";
    src: url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.eot");
    src: url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.svg#GE Flow")format("svg");
}




:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
  --text-long-black: #1a1a1ad5;
}
/* تغيير لون الشريط في Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* عرض الشريط */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* لون المسار */
}

::-webkit-scrollbar-thumb {
  background: var(--main-red-color); /* لون شريط التمرير */
  border-radius: 10px; /* لتنعيم الحواف */
}

::-webkit-scrollbar-thumb:hover {
  background: darkred; /* لون شريط التمرير عند التمرير عليه */
}



/* .Navbar {
  direction: ltr;
} */




/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */





/* 
    Start Media
*/

@media(max-width: 480px) {

  * {
    direction: ltr;
  }
}



/* 
    End Media
*/