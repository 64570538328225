:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --zety-circle: #434240;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
  --text-long-black: #1a1a1ad5;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--gray-color);
}

p {
  line-height: 1.4;
}

.secound-project .my-hero {
  position: relative;
}
.secound-project .my-hero .my-img img {
  position: absolute;
  z-index: -1;
  width: 1420px;
  -o-object-fit: contain;
     object-fit: contain;
}
.secound-project .my-hero .content {
  margin-left: 4em;
  margin-right: 4em;
  padding-top: 2em;
}
.secound-project .my-hero .content .head {
  margin-top: 21em;
}
.secound-project .my-hero .content .head h1 {
  font-size: 60px;
  color: white;
}
.secound-project .my-hero .content .head hr {
  width: 60%;
  background-color: var(--main-red-color);
  border: none;
  height: 1px;
}
.secound-project .my-hero .content .head .title {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
}
.secound-project .my-hero .content .head .title h5 {
  color: var(--gray-color);
}
.secound-project .my-hero .content .head .title i {
  color: var(--main-red-color);
}
.secound-project .my-hero .content p {
  color: var(--gray-color);
  width: 60%;
  padding: 2em 0;
}
.secound-project .main-content {
  margin-top: 16em;
  position: relative;
  background-color: white;
  margin-left: 4em;
  margin-right: 4em;
  border-radius: 0.5em;
}
.secound-project .main-content span.circle {
  width: 500px;
  height: 500px;
  background-color: var(--main-red-color);
  position: absolute;
  left: -100px;
  top: -300px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -2;
}
.secound-project .main-content .blok {
  margin-bottom: 8em;
  display: flex;
  justify-content: space-between;
}
.secound-project .main-content .blok .left {
  margin: 2em;
}
.secound-project .main-content .blok .left h1 {
  color: var(--text-black-color);
}
.secound-project .main-content .blok .left .services {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 2em 2em 1em;
}
.secound-project .main-content .blok .left .services i {
  color: var(--main-red-color);
  font-size: 8px;
}
.secound-project .main-content .blok .left .services h3 {
  color: var(--text-black-color);
}
.secound-project .main-content .blok .left p {
  margin-left: 4em;
}
.secound-project .main-content .blok .right {
  width: 60%;
}
.secound-project .main-content .blok .right img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}

/* 
    Start Media
*/
@media (max-width: 992px) {
  .secound-project {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .secound-project .my-hero .content p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .secound-project .my-hero .content {
    text-align: center;
    margin-left: 0;
  }
  .secound-project .my-hero .content p {
    font-size: 13px;
    width: 100%;
    padding: 2em 4em;
  }
  .secound-project .my-hero .content .head h1 {
    font-size: 30px;
  }
  .secound-project .my-hero .content .head hr {
    margin: 0 auto;
  }
  .secound-project .my-hero .content .head .title {
    justify-content: center;
  }
  .secound-project .main-content .blok {
    flex-direction: column-reverse;
  }
  .secound-project .main-content .blok .left {
    text-align: center;
  }
  .secound-project .main-content .blok .left .services {
    justify-content: center;
  }
  .secound-project .main-content .blok .left p {
    margin-left: 2em;
  }
  .secound-project .main-content .blok .right {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .secound-project .my-hero .content {
    margin-left: 0em;
    margin-right: 0em;
  }
  .secound-project .my-hero .content .title h5 {
    width: 50%;
  }
  .secound-project .my-hero .content .head {
    margin-top: 15em;
    margin-bottom: 3em;
  }
  .secound-project .my-hero .content p {
    font-size: 16px;
    padding: 2em 1.5em;
  }
  .secound-project .main-content {
    margin-top: 13em;
    margin-left: 2em;
    margin-right: 2em;
  }
}/*# sourceMappingURL=style_SecoundProjects.css.map */