:root {
    --main-red-color: #EF4629;
    --zety-color: #848168;
    --zety-circle: #434240;
    --gray-color: #F2F2F2;
    --text-black-color: #1A1A1A;
    --text-long-black: #1a1a1ad5;
}

body {
    //height: 2000px;
    width: 100vw;
    overflow-x: hidden;
    background-color: var(--gray-color);
}

p {
    line-height: 1.4;
}




.first-project {
    .my-hero {
        position: relative;
        .my-img {
            img {
                
                position: absolute;
                z-index: -1;
                //width: 100%;
                width: 1420px;
                //width: 100vw;
                object-fit: contain;
            }
        }

        .content {
            margin-left: 4em;
            margin-right: 4em;
            padding-top: 2em;
            

            .head {
                margin-top: 21em;
                h1 {
                    font-size: 60px;
                    color: white;
                }

                hr {
                    width: 60%;
                    background-color: var(--main-red-color);
                    border: none;
                    height: 1px;
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    margin-top: 2em;

                    h5 {
                        color: var(--gray-color);
                    }

                    i {
                        color: var(--main-red-color);
                    }
                }
            }

            p {
                color: var(--gray-color);
                width: 60%;
                padding: 2em 0;
            }
        }
    }

    .main-content {
        margin-top: 16em;
        position: relative;
        //z-index: 2;
        background-color: white;
        margin-left: 4em;
        margin-right: 4em;
        border-radius: 0.5em;
        //height: 100%;

        span.circle {
            width: 500px;
            height: 500px;
            background-color: var(--main-red-color);
            position: absolute;
            left: -100px;
            top: -300px;
            border-radius: 50%;
            opacity: 0.2;
            z-index: -2;
        }

        .blok {
            margin-bottom: 8em;
            display: flex;
            justify-content: space-between;

            .left {
                margin: 2em;

                h1 {
                    color: var(--text-black-color);
                }

                .services {
                    display: flex;
                    align-items: center;
                    gap: 0.5em;
                    margin: 2em 2em 1em ;

                    i {
                        color: var(--main-red-color);
                        font-size: 8px;
                    }

                    h3 {
                        color: var(--text-black-color);
                    }
                }

                p {
                    margin-left: 4em;
                }
            }

            .right {
                width: 60%;

                img {
                    object-fit: contain;
                    width: 100%;
                }
            }
        }
    }
}



















/* 
    Start Media
*/
@media(max-width:992px) {
    .first-project {
        overflow-x: hidden;
        overflow-y: hidden;
        .my-hero {
            .content {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}










@media(max-width:767px) {
    .first-project {
        .my-hero {
            
            .content {
                text-align: center;
                margin-left: 0;
                //margin-right: auto;
                p {
                    font-size: 13px;
                    width: 100%;
                    padding: 2em 4em;
                }

                .head {
                    h1 {
                        font-size: 30px;
                    }

                    hr {
                        margin: 0 auto;
                    }

                    .title {
                        justify-content: center;
                    }
                }
            }

            
        }

        .main-content {
            .blok {
                flex-direction: column-reverse;

                .left {
                    text-align: center;

                    .services {
                        justify-content: center;
                    }

                    p {
                        margin-left: 2em;
                    }
                }

                .right {
                    width: 100%;
                    //margin: 0 auto;
                }
            }
        }
    }
}














@media(max-width:480px) {
    .first-project {
        //overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            

            .content {
                margin-left: 0em;
                margin-right: 0em;

                .head {
                    margin-top: 15em;
                    margin-bottom: 3em;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        .main-content {
            margin-top: 13em;
            margin-left: 2em;
            margin-right: 2em;

            
        }
    }
}