@import url(https://db.onlinewebfonts.com/c/85040c569cc6193905af9f9ee765baf4?family=GE+Flow);
@font-face {
  font-family: "GE Flow";
  src: url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.eot");
  src: url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.woff") format("woff"), url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/85040c569cc6193905af9f9ee765baf4.svg#GE Flow") format("svg");
}
:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
}

a {
  text-decoration: none;
  color: var(--zety-color);
  font-family: "GE Flow";
}

.flex-nav {
  position: absolute;
  padding-top: 20px;
}
.flex-nav .my-nav {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 100px;
  padding: 0 30px;
}
.flex-nav .my-nav .left p {
  color: #d8d8d8;
  font-size: 13px;
  font-weight: bold;
}
.flex-nav .my-nav .left p span {
  color: white;
  font-size: 14px;
}
.flex-nav .my-nav .left p span:hover {
  cursor: pointer;
}
.flex-nav .my-nav .Center p {
  color: white;
  font-size: 13px;
  font-weight: bold;
}
.flex-nav .my-nav .Center p i {
  margin-right: 5px;
}
.flex-nav .my-nav .Center p:hover {
  cursor: pointer;
}
.flex-nav .my-nav .right p {
  color: white;
  font-size: 13px;
  font-weight: bold;
}
.flex-nav .my-nav .right p i {
  margin-right: 5px;
}
.flex-nav .my-nav .right p:hover {
  cursor: pointer;
}

.Navbar {
  position: absolute;
  z-index: 100;
}
.Navbar .fix-nav {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 5px 3px 5px rgba(48, 48, 48, 0.13);
}
.Navbar .fix-nav .left img {
  width: 70%;
  -o-object-fit: contain;
     object-fit: contain;
  padding-top: 0.8em;
}
.Navbar .fix-nav .center {
  display: flex;
  gap: 0.5em;
  justify-content: space-between;
  align-items: center;
}
.Navbar .fix-nav .center a {
  padding: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  transition: 0.3s;
  position: relative;
}
.Navbar .fix-nav .center a:first-of-type {
  color: var(--main-red-color);
}
.Navbar .fix-nav .center a:hover {
  color: var(--main-red-color);
  transition: 0.3s;
}
.Navbar .fix-nav .center a:hover::after {
  content: "";
  width: 0;
  animation: widthNav 0.5s forwards;
  height: 2px;
  background-color: var(--main-red-color);
  position: absolute;
  left: 10px;
  top: 30px;
}
.Navbar .fix-nav .center i {
  color: var(--main-red-color);
  font-size: 12px;
  transform: translateX(-12px);
}
.Navbar .fix-nav .center .lang {
  background-color: var(--main-red-color);
  color: var(--gray-color);
  padding: 0.5em;
  border-radius: 1em;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.3s ease;
}
.Navbar .fix-nav .center .lang:hover {
  color: var(--main-red-color);
  background-color: var(--gray-color);
  border: 1px solid var(--main-red-color);
  transition: 0.3s ease;
}

.Navbar-for-small-screen {
  position: absolute;
  z-index: 100;
}
.Navbar-for-small-screen .fix-nav {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  box-shadow: 5px 3px 5px rgba(48, 48, 48, 0.13);
}
.Navbar-for-small-screen .fix-nav .left img {
  width: 70%;
  -o-object-fit: contain;
     object-fit: contain;
  padding-top: 0.8em;
}
.Navbar-for-small-screen .fix-nav .right i {
  font-size: 16px;
  color: rgb(44, 44, 44);
  padding: 8px;
  cursor: pointer;
}

.side-bar {
  position: absolute;
  z-index: 99;
}
.side-bar .my-side {
  position: fixed;
  transform: translateX(-100%);
  background-color: white;
  box-shadow: -5px 3px 5px rgba(48, 48, 48, 0.13);
  width: 300px;
  height: 100%;
  text-align: center;
  padding-top: 120px;
}
.side-bar .my-side .links a {
  margin-left: 15px;
  cursor: pointer;
}
.side-bar .my-side .links a i {
  font-size: 18px;
  color: rgb(44, 44, 44);
  padding: 8px;
  cursor: pointer;
}
.side-bar .my-side .links a i:hover {
  animation: iconNav 0.8s forwards;
}
.side-bar .my-side .pages {
  margin-top: 50px;
}
.side-bar .my-side .pages a {
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: 0.3s;
  position: relative;
  display: block;
}
.side-bar .my-side .pages a:first-of-type {
  color: var(--main-red-color);
}
.side-bar .my-side .pages a:hover {
  color: var(--main-red-color);
  transition: 0.3s;
}
.side-bar .my-side .pages a:hover::after {
  content: "";
  width: 0;
  animation: widthNav 0.5s forwards;
  height: 2px;
  background-color: var(--main-red-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
}
.side-bar .my-side .pages a:last-of-type {
  margin-bottom: 3em;
}
.side-bar .my-side .pages .lang {
  background-color: var(--main-red-color);
  color: var(--gray-color);
  padding: 0.5em;
  border-radius: 1em;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.3s ease;
}
.side-bar .my-side .pages .lang:hover {
  color: var(--main-red-color);
  background-color: var(--gray-color);
  border: 1px solid var(--main-red-color);
  transition: 0.3s ease;
}

@keyframes widthNav {
  from {
    width: 0;
  }
  to {
    width: 50px;
  }
}
@keyframes iconNav {
  from {
    transform: scale(1.2);
    transition: 0.2s;
  }
  to {
    transform: scale(1);
    transition: 0.2s;
  }
}
/* 
    Make Media
*/
@media (min-width: 992px) {
  .side-bar, .Navbar-for-small-screen {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .Navbar {
    display: none;
  }
}
@media (max-width: 767px) {
  .flex-nav .my-nav {
    padding: 0 25px;
    gap: 20px;
  }
  .flex-nav .my-nav .left p {
    font-size: 9px;
    font-weight: lighter;
  }
  .flex-nav .my-nav .left p span {
    font-size: 9px;
  }
  .flex-nav .my-nav .Center p {
    font-size: 9px;
    font-weight: lighter;
  }
  .flex-nav .my-nav .right p {
    font-size: 9px;
    font-weight: lighter;
  }
}
@media (max-width: 480px) {
  .Navbar-for-small-screen .fix-nav .left img {
    width: 50%;
    -o-object-fit: contain;
       object-fit: contain;
    padding-top: 0.8em;
  }
}/*# sourceMappingURL=style_Navbar.css.map */