:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --zety-circle: #434240;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
  --text-long-black: #1a1a1ad5;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--gray-color);
}

p {
  line-height: 1.4;
}

.who-we-are .my-hero {
  position: relative;
}
.who-we-are .my-hero .my-img img {
  position: absolute;
  z-index: -1;
  width: 1420px;
  -o-object-fit: contain;
     object-fit: contain;
}
.who-we-are .my-hero .content {
  margin-left: 4em;
  margin-right: 4em;
  padding-top: 5em;
}
.who-we-are .my-hero .content p {
  color: var(--gray-color);
  width: 60%;
  padding: 1.5em 0;
}
.who-we-are .my-hero .content .head h1 {
  font-size: 60px;
  color: white;
}
.who-we-are .my-hero .content .head hr {
  width: 60%;
  background-color: var(--main-red-color);
  border: none;
  height: 1px;
}
.who-we-are .my-hero .content .head .title {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
}
.who-we-are .my-hero .content .head .title h5 {
  color: var(--gray-color);
}
.who-we-are .my-hero .content .head .title i {
  color: var(--main-red-color);
}
.who-we-are .main-content {
  margin-top: 16em;
  position: relative;
  background-color: white;
  margin-left: 4em;
  margin-right: 4em;
  border-radius: 0.5em;
}
.who-we-are .main-content span.circle {
  width: 500px;
  height: 500px;
  background-color: var(--main-red-color);
  position: absolute;
  left: -100px;
  top: -300px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -2;
}
.who-we-are .main-content .blok {
  margin-bottom: 0em;
}
.who-we-are .main-content .blok h1 {
  color: var(--main-red-color);
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
}
.who-we-are .main-content .blok .MSG {
  display: flex;
  align-items: center;
  gap: 6em;
  padding-left: 4em;
  padding-right: 4em;
}
.who-we-are .main-content .blok .MSG i {
  color: var(--main-red-color);
  font-size: 40px;
}
.who-we-are .main-content .blok .MSG p {
  color: var(--text-long-black);
  font-size: 15px;
  width: 60%;
}
.who-we-are .main-content .blok .MSG p span {
  color: var(--main-red-color);
  font-weight: bold;
}
.who-we-are .main-content .blok .MSG img {
  width: 40%;
}
.who-we-are .main-content .blok .MSG .obj h4 {
  color: var(--main-red-color);
}
.who-we-are .main-content .blok .MSG .obj p {
  width: 100%;
}

/* 
    Start Media
*/
@media (min-width: 992px) {
  .who-we-are .main-content .blok:nth-child(3) .MSG i {
    transform: translateY(-5em);
  }
  .who-we-are .main-content .blok:nth-child(3) .MSG p {
    transform: translateY(-13em);
  }
  .who-we-are .main-content .blok:nth-child(4) {
    transform: translateY(-22em);
  }
}
@media (max-width: 992px) {
  .who-we-are {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .who-we-are .my-hero .content p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .who-we-are .my-hero .content {
    text-align: center;
    margin-left: 0;
  }
  .who-we-are .my-hero .content p {
    font-size: 13px;
    width: 100%;
    padding: 2em 4em;
  }
  .who-we-are .my-hero .content .head h1 {
    font-size: 30px;
  }
  .who-we-are .my-hero .content .head hr {
    margin: 0 auto;
  }
  .who-we-are .my-hero .content .head .title {
    justify-content: center;
  }
  .who-we-are .main-content .blok .MSG img {
    display: none;
  }
}
@media (max-width: 480px) {
  .who-we-are .my-hero .content {
    margin-left: 0em;
    margin-right: 0em;
  }
  .who-we-are .my-hero .content p {
    font-size: 12px;
  }
  .who-we-are .main-content {
    margin-top: 8em;
    margin-left: 2em;
    margin-right: 2em;
  }
  .who-we-are .main-content .blok {
    text-align: center;
  }
  .who-we-are .main-content .blok h1 {
    padding-left: 0em;
    padding-right: 0em;
  }
  .who-we-are .main-content .blok .MSG {
    display: block;
    padding-left: 0em;
    padding-right: 0em;
  }
  .who-we-are .main-content .blok .MSG p {
    margin: 1em auto;
    width: 80%;
  }
  .who-we-are .main-content .blok .MSG img {
    display: block;
    margin: 2em auto;
    width: 80%;
  }
  .who-we-are .main-content .blok .MSG .obj h4 {
    width: 80%;
    margin: 2em auto;
  }
  .who-we-are .main-content .blok .MSG .obj p {
    width: 80%;
  }
}/*# sourceMappingURL=style_WhoWeAre.css.map */