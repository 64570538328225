:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --zety-circle: #434240;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
  --text-long-black: #1a1a1ad5;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--gray-color);
}

p {
  line-height: 1.4;
}

.master-our-project .my-hero {
  position: relative;
  background-color: var(--zety-color);
  height: 100vh;
}
.master-our-project .my-hero .my-para {
  width: 80%;
  background-color: var(--zety-color);
  margin: 0 auto;
}
.master-our-project .my-hero .my-para p {
  text-align: center;
  padding: 6.5em 3em 2em;
  color: var(--gray-color);
  font-weight: bold;
  font-size: 18px;
  background-color: #ef4444;
  border-radius: 1em;
}
.master-our-project .my-hero .my-img {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  background-color: var(--zety-color);
  z-index: 1;
  position: relative;
  width: 80%;
  margin: 0 auto;
}
.master-our-project .my-hero .my-img .img-one {
  position: relative;
}
.master-our-project .my-hero .my-img .img-one img {
  width: 100%;
}
.master-our-project .my-hero .my-img .img-one .content {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateX(-50%);
  text-align: center;
}
.master-our-project .my-hero .my-img .img-one .content h1 {
  color: var(--gray-color);
}
.master-our-project .my-hero .my-img .img-one .content i {
  margin-top: 0.5em;
  color: var(--main-red-color);
  font-size: 45px;
}
.master-our-project .my-hero .my-img img {
  width: 25%;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
}
.master-our-project .my-hero .my-img img:nth-child(1) {
  filter: grayscale(1);
  transition: 1s ease;
}
.master-our-project .my-hero .my-img img:nth-child(1):hover {
  filter: grayscale(0);
  transition: 1s ease;
}
.master-our-project .my-hero .my-img img:nth-child(2) {
  filter: grayscale(1);
  transition: 1s ease;
}
.master-our-project .my-hero .my-img img:nth-child(2):hover {
  filter: grayscale(0);
  transition: 1s ease;
}
.master-our-project .my-hero .my-img img:nth-child(3) {
  filter: grayscale(1);
  transition: 1s ease;
}
.master-our-project .my-hero .my-img img:nth-child(3):hover {
  filter: grayscale(0);
  transition: 1s ease;
}
.master-our-project .my-hero .content {
  margin-left: 4em;
  padding-top: 2em;
  z-index: 2;
  position: relative;
}
.master-our-project .my-hero .content p {
  width: 60%;
  padding: 2em 0;
}
.master-our-project .main-content {
  margin-top: 7em;
  position: relative;
  margin-left: 4em;
  margin-right: 4em;
  border-radius: 0.5em;
}
.master-our-project .main-content span.circle {
  width: 500px;
  height: 500px;
  background-color: var(--main-red-color);
  position: absolute;
  left: -100px;
  top: -300px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -2;
}
.master-our-project .main-content .small-title {
  order: 1;
  width: 50%;
  margin-left: 6em;
  padding-top: 2.5em;
  padding-bottom: 6em;
  position: relative;
}
.master-our-project .main-content .small-title h5 {
  font-size: 20px;
}
.master-our-project .main-content .small-title h5 span {
  width: 40px;
  height: 3px;
  background-color: var(--main-red-color);
  position: absolute;
  top: 3em;
}
.master-our-project .main-content .big-title h1 {
  color: var(--text-black-color);
  text-align: center;
  font-size: 45px;
}
.master-our-project .main-content .all-EQUIPMENT {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 6em;
}
.master-our-project .main-content .all-EQUIPMENT .card {
  position: relative;
  width: 30%;
  margin-bottom: 6em;
}
.master-our-project .main-content .all-EQUIPMENT .card h2 {
  color: var(--main-red-color);
  position: absolute;
  left: 43%;
  top: 5%;
  transform: translateX(-35%);
}
.master-our-project .main-content .all-EQUIPMENT .card h2.WHEEL-LOADER {
  font-size: 23px;
}
.master-our-project .main-content .all-EQUIPMENT .card h2.FORKLIFT-TRUCK {
  font-size: 22px;
}
.master-our-project .main-content .all-EQUIPMENT .card h2.MOBILE-ELEVATING-ELECTRICITY,
.master-our-project .main-content .all-EQUIPMENT .card h2.FLAT-BED-TRAILER,
.master-our-project .main-content .all-EQUIPMENT .card h2.TRUCKS-MERCEDES {
  font-size: 20px;
}
.master-our-project .main-content .all-EQUIPMENT .card img {
  width: 100%;
}

/* 
    Start Media
*/
@media (max-width: 992px) {
  .master-our-project {
    overflow-x: hidden;
  }
  .master-our-project .my-hero .my-img .img-one .content {
    left: 20%;
  }
  .master-our-project .my-hero .my-img .img-one .content h1 {
    font-size: 20px;
  }
  .master-our-project .my-hero .my-img .img-one .content i {
    font-size: 20px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2 {
    font-size: 18px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2.WHEEL-LOADER {
    font-size: 16px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2.FORKLIFT-TRUCK {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .master-our-project {
    overflow-x: hidden;
  }
  .master-our-project .my-hero {
    height: auto;
  }
  .master-our-project .my-hero .my-img {
    display: block;
  }
  .master-our-project .my-hero .my-img .img-one {
    text-align: center;
  }
  .master-our-project .my-hero .my-img .img-one img {
    width: 80%;
  }
  .master-our-project .my-hero .my-img .img-one .content {
    left: 40%;
  }
  .master-our-project .my-hero .my-img .img-one .content h1 {
    font-size: 35px;
  }
  .master-our-project .my-hero .my-img .img-one .content i {
    font-size: 40px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card {
    width: 50%;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2 {
    font-size: 18px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2.WHEEL-LOADER {
    font-size: 16px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2.FORKLIFT-TRUCK {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .master-our-project {
    overflow-x: hidden;
  }
  .master-our-project .my-hero {
    height: auto;
  }
  .master-our-project .my-hero .my-para p {
    padding: 6.5em 2em 2em;
  }
  .master-our-project .my-hero .my-img {
    display: block;
  }
  .master-our-project .my-hero .my-img .img-one {
    text-align: center;
  }
  .master-our-project .my-hero .my-img .img-one img {
    width: 100%;
  }
  .master-our-project .my-hero .my-img .img-one .content {
    left: 30%;
  }
  .master-our-project .my-hero .my-img .img-one .content h1 {
    font-size: 25px;
  }
  .master-our-project .my-hero .my-img .img-one .content i {
    font-size: 30px;
  }
  .master-our-project .main-content .all-EQUIPMENT {
    display: block;
  }
  .master-our-project .main-content .all-EQUIPMENT .card {
    width: 130%;
    transform: translateX(-12%);
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2 {
    font-size: 18px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2.WHEEL-LOADER {
    font-size: 16px;
  }
  .master-our-project .main-content .all-EQUIPMENT .card h2.FORKLIFT-TRUCK {
    font-size: 15px;
  }
}/*# sourceMappingURL=style_MasterProject.css.map */