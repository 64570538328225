* {
    scroll-behavior: smooth;
}

:root {
    --main-red-color: #EF4629;
    --zety-color: #848168;
    --zety-circle: #434240;
    --gray-color: #F2F2F2;
    --text-black-color: #1A1A1A;
    --text-long-black: #1a1a1ad5;
}

body {
    //height: 2000px;
    width: 100vw;
    overflow-x: hidden;
    background-color: var(--gray-color);
}

p {
    line-height: 1.4;
}




.our-services {
    .my-hero {
        position: relative;
        background-color: var(--zety-color);
        height: 100vh;

        .my-para {
            width: 80%;
            background-color: var(--zety-color);
            margin: 0 auto;

            p {
                text-align: center;
                //padding-top: 7em;
                padding: 6.5em 3em 2em;
                color: var(--gray-color);
                font-weight: bold;
                font-size: 18px;
                background-color: #ef4444;
                //padding-bottom: 2em;
                border-radius: 1em;
            }
        }

        
        .my-img {
            display: flex;
            justify-content: center;
            //margin-top: 5em;
            padding-top: 1.5em;
            background-color: var(--zety-color);
            z-index: 1;
            position: relative;
            width: 80%;
            margin: 0 auto;

            .img-one {
                position: relative;
                //width: 70%;

                img {
                    width: 100%;
                }

                .content {
                    position: absolute;
                    top: 60%;
                    left: 20%;
                    transform: translateX(-25%);
                    text-align: center;

                    h1 {
                        color: var(--gray-color);
                    }

                    i {
                        margin-top: 0.5em;
                        color: var(--main-red-color);
                        font-size: 45px;
                    }
                }

            }
            img {
                width: 25%;
                cursor: pointer;
                object-fit: contain;
                
                &:nth-child(1) {
                    filter: grayscale(1);
                    transition: 1s ease;

                    &:hover {
                        filter: grayscale(0);
                        transition: 1s ease;
                    }
                }

                &:nth-child(2) {
                    filter: grayscale(1);
                    transition: 1s ease;

                    &:hover {
                        filter: grayscale(0);
                        transition: 1s ease;
                    }
                }
            }
        }

        .content {
            margin-left: 4em;
            padding-top: 2em;
            z-index: 2;
            position: relative;

            p {
                //color: var(--gray-color);
                width: 60%;
                padding: 2em 0;
            }
        }
    }

    .main-content {
        margin-top: 16em;
        position: relative;
        //z-index: 2;
        background-color: white;
        margin-left: 4em;
        margin-right: 4em;
        border-radius: 0.5em;
        padding-bottom: 0.5em;
        //height: 100%;

        span.circle {
            width: 500px;
            height: 500px;
            background-color: var(--main-red-color);
            position: absolute;
            left: -100px;
            top: -400px;
            border-radius: 50%;
            opacity: 0.2;
            z-index: -2;
        }

        .blok {
            margin-bottom: 8em;

            .head {
                margin-top: 7em;
                h1 {
                    font-size: 60px;
                    color: var(--main-red-color);
                    padding-top: 2em;
                    padding-left: 1em;
                    //
                    padding-right: 1em;
                    padding-bottom: 1em;
                }

                hr {
                    width: 60%;
                    background-color: var(--main-red-color);
                    border: none;
                    height: 1px;
                    margin-left: 4em;
                    //
                    margin-right: 4em;
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    margin-top: 2em;
                    padding-left: 4em;
                    ///
                    padding-right: 4em;

                    h5 {
                        color: var(--text-long-black);
                    }

                    i {
                        color: var(--main-red-color);
                    }
                }
            }

            .all-content {
                display: flex;
                .my-left {
                    .my-content {
                        padding-top: 2.5em;
                        padding-left: 4em;
                        //
                        padding-right: 4em;

                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            p {
                                width: 80%;
                            }
                        }
        
                        p {
                            width: 80%;
                            color: var(--text-long-black);
        
                            span {
                                color: var(--main-red-color);
                                font-size: 30px;
                                font-weight: bold;
                            }
                        }
                    }
                }

                .my-right {
                    margin-right: 4em;
                    margin-left: 6em;
                    img {
                        width: 120%;
                        //transform: translateX(-20%);
                        
                    }
                }
            }

        }
    }
}



















/* 
    Start Media
*/
@media(max-width:992px) {
    .our-services {
        overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            .my-img {
                .img-one {
                    .content {
                        left: 20%;

                        h1 {
                            font-size: 20px;
                        }

                        i {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        
    }
}













@media(max-width:767px) {
    .our-services {
        overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            height: auto;

            .my-img {

                display: block;

                .img-one {
                    //margin: 0 auto;
                    text-align: center;

                    img {
                        width: 80%;
                        //margin: 0 auto;
                    }
                    .content {
                        top: 50%;
                        left: 22%;

                        h1 {
                            font-size: 35px;
                        }

                        i {
                            font-size: 40px;
                        }
                    }
                }
            }
        }

        .main-content {
            .blok {
                .all-content {
                    display: block;

                    .my-left {
                        .my-content {
                            &:nth-child(4),
                            &:nth-child(5),
                            &:nth-child(6) {
                                p {
                                    width: 80%;
                                }
                            }
                        }
                    }

                    .my-right {
                        text-align: center;
                        img {
                            width: 60%;
                            transform: translateX(0%);
                            margin-top: 3em;
                        }
                    }
                }
            }
        }
    }
}












@media(max-width:480px) {
    .our-services {
        overflow-x: hidden;
        //overflow-y: hidden;
        .my-hero {
            height: auto;

            .my-para {
                p {
                    padding: 6.5em 2em 2em;
                }
            }

            .my-img {

                display: block;

                .img-one {
                    //margin: 0 auto;
                    text-align: center;

                    img {
                        width: 100%;
                        //margin: 0 auto;
                    }
                    .content {
                        top: 35%;
                        left: 15%;

                        h1 {
                            font-size: 25px;
                        }

                        i {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        .main-content {
            margin-left: 1em;
            margin-right: 1em;
            margin-top: 10em;
            //transform: translateY(-10%);

            .blok {
                .head {

                    h1 {
                        font-size: 25px;
                        text-align: center;
                    }

                    .title {
                        h5 {
                            font-size: 10px;
                        }
                    }
                }

                .all-content {
                    .my-left {
                        .my-content {
                            padding-right: 0em;
                        }
                    }

                    .my-right {
                        margin-right: 0em;
                        margin-left: 0em;
                    }
                }
            }
        }
    }
}






