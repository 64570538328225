:root {
  --footer-color: #1A1A1A;
  --gray-color: #F2F2F2;
}

.my-footer {
  background-color: var(--footer-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-footer img {
  width: 80px;
  margin-left: 4em;
  margin-right: 4em;
}
.my-footer .my-links {
  display: flex;
  gap: 1.5em;
  margin-right: 4em;
  margin-left: 4em;
}
.my-footer .my-links a {
  cursor: pointer;
}
.my-footer .my-links a:hover {
  color: var(--gray-color);
}

@media (max-width: 480px) {
  .my-footer img {
    margin-left: 2em;
  }
  .my-footer .my-links {
    margin-right: 3em;
    margin-left: 1em;
  }
}/*# sourceMappingURL=style_Footer.css.map */