:root {
  --main-red-color: #EF4629;
  --zety-color: #848168;
  --zety-circle: #434240;
  --gray-color: #F2F2F2;
  --text-black-color: #1A1A1A;
  --text-long-black: #1a1a1ad5;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--gray-color);
}

p {
  line-height: 1.4;
}

.our-partner .my-hero {
  position: relative;
}
.our-partner .my-hero .my-img img {
  position: absolute;
  z-index: -1;
  width: 1420px;
  -o-object-fit: contain;
     object-fit: contain;
}
.our-partner .my-hero .content {
  margin-left: 4em;
  margin-right: 4em;
  padding-top: 9em;
}
.our-partner .my-hero .content .head {
  margin-top: 21em;
}
.our-partner .my-hero .content .head h1 {
  font-size: 60px;
  color: white;
}
.our-partner .my-hero .content .head hr {
  width: 60%;
  background-color: var(--main-red-color);
  border: none;
  height: 1px;
}
.our-partner .my-hero .content .head .title {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
}
.our-partner .my-hero .content .head .title h5 {
  color: var(--gray-color);
}
.our-partner .my-hero .content .head .title i {
  color: var(--main-red-color);
}
.our-partner .my-hero .content p {
  color: var(--gray-color);
  width: 60%;
  padding: 2em 0;
}
.our-partner .main-content {
  margin-top: 16em;
  position: relative;
  margin-left: 4em;
  margin-right: 4em;
  border-radius: 0.5em;
}
.our-partner .main-content span.circle {
  width: 500px;
  height: 500px;
  background-color: var(--main-red-color);
  position: absolute;
  left: -100px;
  top: -300px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -2;
}
.our-partner .main-content .clints {
  position: relative;
}
.our-partner .main-content .clints .tow-circle {
  position: relative;
}
.our-partner .main-content .clints .tow-circle i {
  position: absolute;
  right: -50px;
  top: -50px;
  font-size: 300px;
  color: var(--zety-circle);
}
.our-partner .main-content .clints .tow-circle i:nth-child(2) {
  right: -50px;
  top: -50px;
  color: #55534f;
  font-size: 280px;
}
.our-partner .main-content .clints span.circle {
  width: 400px;
  height: 400px;
  background-color: var(--zety-circle);
  position: absolute;
  left: -100px;
  top: -150px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: 0;
}
.our-partner .main-content .clints .content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 5;
}
.our-partner .main-content .clints .content .left {
  width: 50%;
  margin-left: 6em;
  padding-top: 6em;
  padding-bottom: 6em;
  position: relative;
}
.our-partner .main-content .clints .content .left h5 {
  color: var(--text-long-black);
  font-size: 16px;
}
.our-partner .main-content .clints .content .left h5 span {
  width: 40px;
  height: 3px;
  background-color: var(--main-red-color);
  position: absolute;
  top: 7.5em;
}
.our-partner .main-content .clints .content .right {
  color: var(--text-long-black);
}
.our-partner .main-content .clints .logos {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  gap: 1.5em;
}
.our-partner .main-content .clints .logos img {
  width: 220px;
  margin-bottom: 6em;
}

/* 
    Start Media
*/
@media (max-width: 992px) {
  .our-partner {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .our-partner .my-hero .content p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .our-partner .my-hero .content {
    text-align: center;
    margin-left: 0;
  }
  .our-partner .my-hero .content p {
    font-size: 13px;
    width: 100%;
    padding: 2em 4em;
  }
  .our-partner .my-hero .content .head h1 {
    font-size: 30px;
  }
  .our-partner .my-hero .content .head hr {
    margin: 0 auto;
  }
  .our-partner .my-hero .content .head .title {
    justify-content: center;
  }
  .our-partner .main-content .clints .content {
    text-align: center;
    flex-direction: column-reverse;
    padding-top: 5em;
  }
  .our-partner .main-content .clints .content .left {
    margin-left: 0em;
  }
  .our-partner .main-content .clints .logos {
    flex-wrap: wrap;
  }
  .our-partner .main-content .clints .logos img {
    margin-bottom: 2em;
  }
}
@media (max-width: 480px) {
  .our-partner .my-hero .content {
    margin-left: 0em;
    margin-right: 0em;
  }
  .our-partner .my-hero .content .head {
    margin-top: 15em;
    margin-bottom: 3em;
  }
  .our-partner .my-hero .content p {
    font-size: 16px;
    padding: 2em 1.5em;
  }
  .our-partner .main-content {
    margin-top: 20em;
  }
  .our-partner .main-content .clints {
    width: 100%;
    overflow: hidden;
  }
  .our-partner .main-content .clints .content .left {
    width: 100%;
  }
}/*# sourceMappingURL=style_OurPartner.css.map */